import React from 'react';
import C_01 from '../../../images/products/C_01.jpg';
import C_02 from '../../../images/products/C_02.jpg';
import C_03 from '../../../images/products/C_03.jpg';
import C_04 from '../../../images/products/C_04.jpg';
import C_05 from '../../../images/products/C_05.jpg';
import C_06 from '../../../images/products/C_06.jpg';
import C_07 from '../../../images/products/C_07.jpg';
import C_08 from '../../../images/products/C_08.jpg';

import C_TOP from '../../../images/DELTADM.jpg';

export const ProductsC = (props) => {
  return (
    <div>
      <img className="" src={C_TOP}/>
      <br></br>
      <h2 className="productsTitle">COMPRESSOR 自動回復型碰撞緩衝設施</h2>
      <ul>
        <li className="point-font">◎ Compressor是一款能自動回復的碰撞緩衝設施。</li>
        <li>◎ 通過美國MASH (NCHRP 350) TL-2及 TL-3測試報告。</li>
        <li>◎ 在受撞後不需要高額的零件維修費用，可將整體的修復費用降至最低，輕微的碰撞甚至是不需要維修的。</li>
        <li>◎ 總體長度約6.5公尺，較其它緩撞設施短，不需占用過多的空間，可增加用路人的反應距離。</li>
        <li className="point-font">◎ Compressor結合了完整的鐵製結構底座，僅需10組固定螺栓，即可快速安全的安裝完成，減少繁複的施工程序。</li>
        <li>◎ 特製的能量吸收筒擁有高密度的聚乙烯，能更有效的吸收近距離的撞擊力道。</li>
        <li>◎ 側邊高強度的金屬側板可導正並減緩側面撞擊，有效的降低傷害。</li>
        <li className="point-font">◎ 可直接安裝於瀝青路面，無須路面刨除，縮短施工時間。</li>
      </ul>
      <h4 className="mt-5">設施規格</h4>
      <ul>
        <li>◎ 整體長度 約6.48公尺</li>
        <li>◎ 島頭寬度 約1.1公尺</li>
        <li>◎ 末端寬度 約1.2公尺(可依受保護區域另外設計，亦有寬型設計)</li>
        <li>◎ 設施高度 約1.4公尺</li>
      </ul>
      <h4 className="mt-5">產品特點</h4>
      <ul>
        <li>◎ 可同時接受高速(TL-3)和低速(TL-2)的撞擊。</li>
        <li>◎ 快速簡單的施工程序，減少施工人員的風險。</li>
        <li>◎ 零件及能量吸收筒均可重複使用，降低維修成本。</li>
        <li>◎ 可導正偏離車道的車輛，導正側面撞擊的車輛。</li>
        <li>◎ 固定且堅固的金屬底座及後支撐防護體，提供最佳的支撐力。</li>
        <li>◎ 可依受保護的鼻端設計為寬型，更確實更有效的保護用路人。</li>
      </ul>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_05}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_06}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_07}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_08}/>
          </div>
        </div>
      </div>
    </div>
  )
}